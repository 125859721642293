import { Component, ElementRef, Input, booleanAttribute } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-badge, a[ui-badge], span[ui-badge]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
})
export class BadgeComponent {
  @Input({ transform: booleanAttribute }) color: 'accent' | 'secondary' =
    'secondary';

  constructor(private _elementRef: ElementRef<HTMLElement>) {
    _elementRef.nativeElement.classList.add(
      'flex',
      'h-6',
      'items-center',
      'rounded-xl',
      'px-3',
      'text-xs',
    );

    if (this.color === 'accent') {
      _elementRef.nativeElement.classList.add('bg-accent-base', 'text-white');
    }

    if (this.color === 'secondary') {
      _elementRef.nativeElement.classList.add(
        'bg-secondary-base',
        'dark:bg-dark-active',
        'dark:text-white',
      );
    }
  }

  /**
   * Returns the host element.
   * @returns The host element.
   */
  private getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  /**
   * Adds the given classes to the host element.
   * @param className
   */
  private addToClassList(className: string): void {
    const classes = className.split(' ');
    (this.getHostElement() as HTMLElement).classList.add(...classes);
  }
}
