import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';
import { Category } from '@lysties/classification/data-access';
import { timeToDate } from '@lysties/common/helpers';
import { Location } from '@lysties/locations/data-access';
import { Photo } from '@lysties/photos/data-access';

import { OpeningHours } from './opening-hours';
import { Feature } from './feature';

@ApiResource('Business')
export class Business extends BaseResource {
  @Attribute()
  slug = '';

  @Attribute()
  name = '';

  @Attribute()
  about = '';

  @Attribute()
  phoneNumber = '';

  @Attribute()
  website = '';

  @Attribute()
  rating = 0;

  @Attribute()
  ratingCount = 0;

  @Attribute()
  isOnline = false;

  @Attribute()
  isClosed = false;

  @Attribute()
  isClaimed = false;

  @Attribute()
  isSaved = false;

  @Attribute()
  todayIsOpen: boolean | null = null;

  @Attribute(undefined, undefined, timeToDate)
  todayOpeningTime: Date | null = null;

  @Attribute(undefined, undefined, timeToDate)
  todayClosingTime: Date | null = null;

  @Attribute()
  todayClosesNextDay = false;

  @Attribute()
  lastReviewId = '';

  @Attribute()
  lastReview = '';

  @Attribute(Category)
  categories: Category[] = [];

  @Attribute(Photo)
  photos: Photo[] = [];

  @Attribute(Location)
  place: Location = new Location();

  @Attribute(OpeningHours)
  hours: OpeningHours[] = [];

  @Attribute(Feature)
  features: Feature[] = [];

  constructor(slug = '') {
    super();

    this.slug = slug;
  }
}
