import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroChevronDown } from '@ng-icons/heroicons/outline';
import { MenuTriggerForDirective } from '../menu/menu-trigger-for.directive';
import { MenuComponent } from '../menu/menu/menu.component';
import { DropdownOption } from './dropdown-option';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.component.html',
  standalone: true,
  imports: [
    CommonModule,
    NgIconComponent,
    MenuComponent,
    MenuTriggerForDirective,
    ButtonComponent,
  ],
  viewProviders: [provideIcons({ heroChevronDown })],
})
export class DropdownComponent {
  @Input() options: DropdownOption[] = [];
  @Input() selectedOption!: DropdownOption;
  @Output() readonly selected: EventEmitter<DropdownOption> =
    new EventEmitter<DropdownOption>();

  select(option: DropdownOption): void {
    this.selected.emit(option);
  }
}
