import { bootstrapApplication } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

if (process.env['NX_SENTRY_DSN']) {
  Sentry.init({
    dsn: process.env['NX_SENTRY_DSN'],
    environment:
      process.env['NX_API_ROOT'] === 'https://api.lysties.com'
        ? 'production'
        : 'staging',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
