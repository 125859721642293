import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';

import { Item } from './item';

@Component({
  selector: 'ui-items-select',
  templateUrl: './items-select.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class ItemsSelectComponent {
  @Input() label = 'Items';
  @Input()
  set items(items: Item[]) {
    this._items = items;
    this.initializeForm(items);
  }

  get items(): Item[] {
    return this._items;
  }

  @Input()
  set initial(items: string[]) {
    this._selected = items;
    this.initializeForm(this._items);
  }

  get initial(): string[] {
    return this._selected;
  }

  @Output() readonly selected = new EventEmitter<string[]>();

  form = new FormGroup({
    items: new FormArray<FormControl<boolean>>([]),
  });

  private _selected: string[] = [];
  private _items: Item[] = [];

  onSubmit(): void {
    const items: string[] = [];
    (this.form.get('items') as FormControl).value.forEach(
      (value: boolean, index: number) => {
        if (value) {
          const selected = this._items[index];
          if (selected.code) {
            items.push(selected.code);
          }
        }
      },
    );

    this.selected.emit(items);
  }

  private initializeForm(items: Item[]): void {
    this.form.controls.items = new FormArray<FormControl<boolean>>([]);
    items.forEach((item: Item) => {
      if (item.code) {
        const control = new FormControl(this.initial.includes(item.code));
        this.form.controls.items.push(control as FormControl);
      }
    });
  }
}
