<div
  class="maintenance flex min-h-screen w-screen fixed top-0 left-0 right-0 flex-col text-white"
>
  <h1 class="flex items-center py-10">
    <a
      href="/"
      aria-hidden=""
      class="mx-auto flex items-center text-2xl font-semibold"
    >
      <img
        class="mr-2"
        src="/assets/images/logo-white.svg"
        width="32"
        height="32"
        alt=""
      />
      <span>Lysties</span>
    </a>
  </h1>
  <div class="mx-auto my-auto flex max-w-xl flex-col items-center">
    <img
      src="/assets/images/taken.svg"
      alt="Maintenance Mode"
      width="150"
      i18n-alt
    />
    <h2 class="my-10 text-center text-3xl font-bold" i18n>
      Sorry, we are under maintenance!
    </h2>
    <p class="text-center" i18n>
      Got a business you've been dying to review or checkout? We'll be back up
      and running soon so you can do that and more.
    </p>

    <p class="my-5 text-center" i18n>For more info, check our socials.</p>
    <div class="flex justify-center space-x-5">
      <a
        href="https://twitter.com/lysties"
        target="_blank"
        rel="noopener"
        i18n-aria-label
        aria-label="Follow us on Twitter"
      >
        <ng-icon name="bootstrapTwitterX" size="32"></ng-icon>
      </a>
      <a
        href="https://instagram.com/lysties"
        target="_blank"
        rel="noopener"
        i18n-aria-label
        aria-label="Follow us on Instagram"
      >
        <ng-icon name="featherInstagram" size="32"></ng-icon>
      </a>
      <a
        href="https://facebook.com/lysties"
        target="_blank"
        rel="noopener"
        i18n-aria-label
        aria-label="Follow us on Facebook"
      >
        <ng-icon name="featherFacebook" size="32"></ng-icon>
      </a>
    </div>
  </div>
</div>
