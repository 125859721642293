import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Results } from '@lysties/common/api';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { finalize } from 'rxjs';

import { TruncatePipe } from '@lysties/common/helpers';
import { Review, ReviewService } from '@lysties/reviews/data-access';
import {
  AvatarComponent,
  ButtonComponent,
  CardComponent,
  RatingComponent,
} from '@lysties/shared/ui';

@Component({
  selector: 'app-recent-reviews',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    AvatarComponent,
    ButtonComponent,
    CardComponent,
    RatingComponent,
    TruncatePipe,
  ],
  templateUrl: './recent-reviews.component.html',
})
export class RecentReviewsComponent implements OnInit {
  reviews: Review[] = [];
  nextPage = 1;
  loading = false;

  constructor(private reviewService: ReviewService) {}

  ngOnInit(): void {
    this.loadActivity();
  }

  loadActivity(): void {
    const query = {
      'page[number]': this.nextPage.toString(),
      'page[size]': '9',
    };
    this.loading = true;
    this.reviewService
      .list(query)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (reviews: Results<Review>) => {
          this.reviews.push(...reviews.data);
          this.nextPage =
            reviews.pagination.next !== null ? this.nextPage + 1 : 1;
        },
      });
  }
}
