import { ApiDetailResponse } from '@lysties/common/api';
import { InjectionToken } from '@angular/core';

export class Token {
  access = '';
  refresh = '';

  constructor(response: ApiDetailResponse) {
    if (response.data?.attributes) {
      this.access = response.data.attributes['access'] as string;
      this.refresh = response.data.attributes['refresh'] as string;
    }
  }
}

export const GOOGLE_ACCOUNTS_CLIENT_ID = new InjectionToken<string>(
  'Google accounts client ID.'
);

export const AUTH_ACCESS_TOKEN_KEY = new InjectionToken<string>(
  'Injection token for the authorization access token key.'
);
export const AUTH_REFRESH_TOKEN_KEY = new InjectionToken<string>(
  'Injection token for the authorization refresh token key.'
);
