<button type="button" ui-stroked-button [uiMenuTriggerFor]="optionsMenu">
  <ng-content></ng-content>
  <ng-icon name="heroChevronDown" size="14"></ng-icon>
</button>
<ui-menu #optionsMenu>
  @for (option of options; track option.label) {
    <button
      class="hover:bg-secondary-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active px-4 py-2 text-left text-sm"
      (click)="select(option)"
    >
      {{ option.label }}
    </button>
  }
</ui-menu>
