import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RetrieveMixin } from '@lysties/common/api';

import { City } from './city';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  private retrieveMixin: RetrieveMixin<City>;
  private url = '/cities';

  constructor(http: HttpClient) {
    this.retrieveMixin = new RetrieveMixin<City>(http, this.url, City);
  }

  retrieve(id: string): Observable<City> {
    return this.retrieveMixin.retrieve(id);
  }
}
