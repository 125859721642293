<input
  type="text"
  [placeholder]="placeholder"
  class="placeholder-muted-base dark:placeholder-dark-muted w-full border-none bg-transparent px-0 py-2.5 text-base text-black outline-none focus:ring-transparent md:text-sm lg:text-sm dark:text-white"
  #input
  role="combobox"
  aria-autocomplete="list"
  spellcheck="false"
  [attr.aria-expanded]="isOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (focus)="onFocus()"
  (input)="onInput($event)"
/>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayWidth]="width"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: offsetX,
      offsetY: offsetY
    }
  ]"
>
  <div
    class="border-t-light-active dark:border-t-dark-active dark:bg-dark-base dark:border-dark-active flex w-full flex-col border-t bg-white md:rounded-b-2xl md:py-2 md:drop-shadow-lg dark:border"
    role="listbox"
    aria-live="polite"
  >
    <div class="flex flex-col">
      @for (option of options; track $index) {
        <div
          class="hover:bg-light-base dark:hover:bg-dark-active dark:focus:bg-dark-active focus:bg-light-base cursor-pointer px-4 py-2 text-sm"
          (click)="onSelect(option)"
          role="option"
        >
          @for (part of splitLabel(option.label); track $index) {
            <span
              [ngClass]="{
                'font-bold': (part | lowercase) === (input.value | lowercase)
              }"
              >{{ part }}</span
            >
          }
        </div>
      }
    </div>
  </div>
</ng-template>
