import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';
import { City } from './city';

@ApiResource('Neighborhood')
export class Neighborhood extends BaseResource {
  @Attribute()
  name = '';

  @Attribute()
  slug = '';

  @Attribute(City)
  city = new City();

  constructor(init?: Partial<Neighborhood>) {
    super(init);

    Object.assign(this, init);
  }

  display(): string {
    return this.name
      ? `${this.name}, ${this.city.display()}`
      : this.city.display();
  }
}
