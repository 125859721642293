import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Observable, finalize } from 'rxjs';

import { Category, CategoryService } from '@lysties/classification/data-access';
import { Results } from '@lysties/common/api';
import {
  City,
  LocationService,
  Location,
} from '@lysties/locations/data-access';

@Component({
  selector: 'app-featured-categories',
  standalone: true,
  imports: [CommonModule, RouterModule, NgOptimizedImage],
  templateUrl: './featured-categories.component.html',
})
export class FeaturedCategoriesComponent implements OnInit {
  categories$!: Observable<Results<Category>>;
  loading = false;
  city: City = new City();

  constructor(
    private categoryService: CategoryService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.locationService.getLocation().subscribe({
      next: (location: Location) => {
        this.city = location.neighborhood.city;
      },
    });
    this.loadCategories();
  }

  private loadCategories(): void {
    this.loading = true;
    this.categories$ = this.categoryService
      .list({ 'page[size]': '7', 'filter[featured]': 'true' })
      .pipe(finalize(() => (this.loading = false)));
  }
}
