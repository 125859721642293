<div class="h-2 w-full">
  <div
    class="absolute flex h-full w-full"
    [ngClass]="{ 'bg-red-300': variant === 'primary' }"
  ></div>
  <div
    class="main-progress absolute -left-[145%] h-full w-full scale-x-100 transition-none"
  ></div>
  <div
    class="main-translate absolute -left-[54%] block h-full w-full origin-top-left transition-none"
  >
    <span
      class="main-scale absolute inline-block w-full border border-t-4"
      [ngClass]="{ 'border-primary-base': variant === 'primary' }"
    ></span>
  </div>
</div>
