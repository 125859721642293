import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

const COLORS: { [key: string]: string } = {
  a: 'bg-red-500',
  b: 'bg-yellow-500',
  c: 'bg-green-500',
  d: 'bg-blue-500',
  e: 'bg-indigo-500',
  f: 'bg-purple-500',
  g: 'bg-pink-500',
  h: 'bg-red-600',
  i: 'bg-yellow-600',
  j: 'bg-green-600',
  k: 'bg-blue-600',
  l: 'bg-indigo-600',
  m: 'bg-purple-600',
  n: 'bg-pink-600',
  o: 'bg-red-700',
  p: 'bg-yellow-700',
  q: 'bg-green-700',
  r: 'bg-blue-700',
  s: 'bg-indigo-700',
  t: 'bg-purple-700',
  u: 'bg-pink-700',
  v: 'bg-red-800',
  w: 'bg-yellow-800',
  x: 'bg-green-800',
  y: 'bg-blue-800',
  z: 'bg-indigo-800',
};

@Component({
  selector: 'ui-avatar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnInit {
  @Input() image = '';
  @Input() initials = '';
  @Input() size = 40;

  colorClass = '';

  ngOnInit(): void {
    this.initials = this.initials === '' ? 'A' : this.initials;
    this.colorClass = COLORS[this.initials[0].toLowerCase()];
  }
}
