export const timeToDate = (time: unknown): Date | null => {
  if (time) {
    const date = new Date(`1970-01-01T${time}Z`);
    date.setUTCHours(date.getUTCHours() - 1);

    return date;
  }

  return null;
};
