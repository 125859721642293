import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';
import { City } from '@lysties/locations/data-access';

@ApiResource('Profile')
export class Profile extends BaseResource {
  @Attribute()
  avatar = '';

  @Attribute()
  bio = '';

  @Attribute()
  firstName = '';

  @Attribute()
  lastName = '';

  @Attribute(Date, undefined)
  birthdate?: Date;

  @Attribute(City, 'city')
  city?: City;

  constructor(init?: Partial<Profile>) {
    super();

    Object.assign(this, init);
  }

  display(): string {
    return `${this.firstName} ${this.lastName.charAt(0)}.`;
  }

  initials(): string {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
