import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ListMixin } from '@lysties/common/api';

import { Highlight } from './highlight';

@Injectable({
  providedIn: 'root',
})
export class HighlightService {
  private listMixin: ListMixin<Highlight>;
  private url = '/highlights';

  constructor(http: HttpClient) {
    this.listMixin = new ListMixin<Highlight>(http, this.url, Highlight);
  }

  list(query?: { [key: string]: string }) {
    return this.listMixin.list(query);
  }
}
