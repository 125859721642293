/**
 * Metadata for a JSON API attribute
 */
export interface AttributeMetadata {
  // Property name on the class
  property: string;
  // Type of the property
  type: unknown;
  // Attribute name in the JSON API response
  attribute: string;
  // Used to transform the value from the JSON API response
  transform: (value: unknown) => unknown;
}

export const attributeMetadata = new Map<unknown, AttributeMetadata[]>();

/**
 * Metadata for JSON API resource.
 */
export const resourceMetadata = new Map<unknown, string>();
