import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject } from 'rxjs';

import { GOOGLE_MAPS_API_KEY } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private mapReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(GOOGLE_MAPS_API_KEY) private apiKey: string,
  ) {}

  initializeMap(): void {
    const loader = new Loader({
      apiKey: this.apiKey,
      version: 'weekly',
    });

    if (isPlatformBrowser(this.platformId)) {
      loader
        .importLibrary('maps')
        .then(() => this.mapReady$.next(true))
        .catch((error) => {
          console.error(error);
        });
      loader.importLibrary('marker').then();
    }
  }

  getMapReady(): BehaviorSubject<boolean> {
    return this.mapReady$;
  }
}
