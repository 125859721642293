import { ElementRef } from '@angular/core';

export class BaseComponent {
  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  /**
   * Returns the host element.
   * @returns The host element.
   */
  protected getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  /**
   * Checks if the host element has one of the given attributes.
   * @param attributes
   * @returns boolean
   */
  protected hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some((attribute) =>
      this.getHostElement().hasAttribute(attribute),
    );
  }

  /**
   * Adds the given classes to the host element.
   * @param className
   */
  protected addToClassList(className: string): void {
    const classes = className.split(' ');
    (this.getHostElement() as HTMLElement).classList.add(...classes);
  }
}
