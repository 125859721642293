<div class="flex min-h-screen flex-col">
  <app-header [transparent]="focus" [focus]="focus"></app-header>
  <main
    class="flex flex-1 flex-col"
    [ngClass]="{ 'pt-[137px] lg:pt-[79px]': !focus }"
  >
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
