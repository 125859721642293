import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, of, throwError } from 'rxjs';

import { MaintenanceComponent } from '@lysties/blocks';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const dialog = inject(Dialog);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 503) {
        dialog.open(MaintenanceComponent, {
          disableClose: true,
          autoFocus: false,
        });
        return of();
      }
      return throwError(() => error);
    }),
  );
};
