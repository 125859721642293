import { Injectable } from '@angular/core';
import { ListMixin, Results } from '@lysties/common/api';
import { RatingCategory } from './rating-category';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RatingCategoryService {
  private listMixin: ListMixin<RatingCategory>;

  constructor(http: HttpClient) {
    this.listMixin = new ListMixin<RatingCategory>(
      http,
      '/rating-categories',
      RatingCategory,
    );
  }

  list(
    query: { [key: string]: string } = {},
  ): Observable<Results<RatingCategory>> {
    return this.listMixin.list(query);
  }
}
