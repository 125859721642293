import { Component, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'button[ui-menu-item], a[ui-menu-item]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent {
  constructor(private _elementRef: ElementRef<HTMLElement>) {
    // Button base classes:
    this.getHostElement().classList.add(
      'hover:bg-secondary-base',
      'dark:bg-dark-base',
      'focus:bg-secondary-base',
      'dark:hover:bg-dark-active',
      'dark:focus:bg-dark-active',
      'px-4',
      'py-4',
      'text-sm',
      'lg:py-2',
      'text-left',
    );
  }

  /**
   * Returns the host element.
   * @returns The host element.
   */
  private getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }
}
