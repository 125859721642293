import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RetrieveMixin } from '@lysties/common/api';

import { Neighborhood } from './neighborhood';

@Injectable({
  providedIn: 'root',
})
export class NeighborhoodService {
  private retrieveMixin: RetrieveMixin<Neighborhood>;
  private url = '/neighborhoods';

  constructor(http: HttpClient) {
    this.retrieveMixin = new RetrieveMixin<Neighborhood>(
      http,
      this.url,
      Neighborhood
    );
  }

  retrieve(id: string): Observable<Neighborhood> {
    return this.retrieveMixin.retrieve(id);
  }
}
