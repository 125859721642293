import { snakeToCamel } from '@lysties/common/helpers';

export interface Error {
  code: string;
  detail: string;
  status: string;
  source?: { pointer: string };
}

export class ApiError {
  constructor(
    public code: string,
    public detail?: string,
    public status?: string,
    public source?: string,
  ) {}

  static fromErrors(errors: Error[]): ApiError[] {
    const httpErrors: ApiError[] = [];
    errors.forEach((error) => {
      const httpError = new ApiError(error.code, error.detail, error.status);

      if (error?.source?.pointer) {
        let cleanedSource = error.source.pointer.replace(
          /\/data\/attributes\//,
          '',
        );
        cleanedSource = cleanedSource.replace(/\/data/, '');
        cleanedSource = cleanedSource.replace(/\//g, '.');
        httpError.source = snakeToCamel(cleanedSource);
      }

      httpErrors.push(httpError);
    });

    return httpErrors;
  }
}
