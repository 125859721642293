import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable, catchError, map, of } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { ApiDetailResponse, BaseMixin } from '@lysties/common/api';
import { StorageService, WINDOW_TOKEN } from '@lysties/common/browser';
import { User, UserService } from '@lysties/users/data-access';

import { ConfirmCredentials, GoogleUserCredentials } from './credentials';
import { AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY, Token } from './token';

interface AuthState {
  userId: string;
  expiresAt: Date;
  access: string;
  refresh: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseMixin {
  private state?: AuthState;
  private url = '/auth';
  private user?: User;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private userService: UserService,
    @Inject(WINDOW_TOKEN) private window: Window,
    @Optional() @Inject(AUTH_ACCESS_TOKEN_KEY) private accessTokenKey: string,
    @Optional() @Inject(AUTH_REFRESH_TOKEN_KEY) private refreshTokenKey: string,
  ) {
    super();
  }

  signin(email: string): Observable<void> {
    return this.http
      .post(`${this.url}/signin/`, {
        data: { type: 'SigninWithEmailView', attributes: { email } },
      })
      .pipe(catchError(this.handleErrors));
  }

  confirmSignup(credentials: ConfirmCredentials): Observable<Token> {
    return this.http
      .post<ApiDetailResponse>(`${this.url}/signup/confirm/`, {
        data: { type: 'SignupWithEmailConfirmView', attributes: credentials },
      })
      .pipe(
        map((response: ApiDetailResponse) => new Token(response)),
        catchError(this.handleErrors),
      );
  }

  confirmSignin(credentials: ConfirmCredentials): Observable<Token> {
    return this.http
      .post<ApiDetailResponse>(`${this.url}/signin/confirm/`, {
        data: { type: 'SigninWithEmailConfirmView', attributes: credentials },
      })
      .pipe(
        map((response: ApiDetailResponse) => new Token(response)),
        catchError(this.handleErrors),
      );
  }

  authenticateWithGoogle(
    credentials: GoogleUserCredentials,
  ): Observable<Token> {
    return this.http
      .post<ApiDetailResponse>(`${this.url}/google/token/`, {
        data: { type: 'GoogleTokenView', attributes: credentials },
      })
      .pipe(
        map((response: ApiDetailResponse) => new Token(response)),
        catchError(this.handleErrors),
      );
  }

  login(token: Token): boolean {
    if (
      token.access &&
      token.refresh &&
      this.initSession(token.access, token.refresh)
    ) {
      this.storage.setItem(this.accessTokenKey, token.access);
      this.storage.setItem(this.refreshTokenKey, token.refresh);
      const redirect = this.storage.getItem('redirect') ?? '/';
      this.window.location.assign(redirect);

      return true;
    }

    return false;
  }

  signup(data: unknown): Observable<void> {
    return this.http
      .post<ApiDetailResponse>(`${this.url}/signup/`, {
        data: { attributes: data, type: 'User' },
      })
      .pipe(
        map(() => undefined),
        catchError(this.handleErrors),
      );
  }

  getAccessToken(): string {
    if (this.state?.access) {
      return this.state.access;
    }

    return '';
  }

  isAuthenticated(force = false): boolean {
    if (force) {
      this.initSession();
    }

    const isValidState = Boolean(this.state?.access && this.state?.refresh);
    const isNotExpired = Boolean(
      this.state && this.state.expiresAt > new Date(),
    );

    return isValidState && isNotExpired;
  }

  signOut(reload = false): void {
    this.state = undefined;
    this.storage.clear();

    if (reload) {
      this.window.location.assign('/');
    }
  }

  loadUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }

    return this.userService.getMe().pipe(
      map((user: User) => {
        this.user = user;
        return user;
      }),
    );
  }

  getUser(): User | undefined {
    return this.user;
  }

  private initSession(accessToken?: string, refreshToken?: string): boolean {
    // Load the tokens from the storage if not provided.
    const access = accessToken ?? this.storage.getItem(this.accessTokenKey);
    const refresh = refreshToken ?? this.storage.getItem(this.refreshTokenKey);

    // We need both tokens to be available
    if (!access || !refresh) {
      return false;
    }

    try {
      const payload: { [key: string]: string } = jwtDecode(access);
      if (refresh && access && payload['user_id'] && payload['exp']) {
        this.state = {
          userId: payload['user_id'],
          expiresAt: new Date(Number.parseInt(payload['exp']) * 1000),
          refresh,
          access,
        };
        return true;
      }
    } catch (error) {
      return false;
    }

    return false;
  }
}
