import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroChevronLeftSolid,
  heroChevronRightSolid,
} from '@ng-icons/heroicons/solid';
import { bootstrapCaretDownFill } from '@ng-icons/bootstrap-icons';

import { DAYS, MONTHS } from '@lysties/common/constants';

import { ButtonComponent } from '../button/button.component';
import { CalendarDay } from './calendar-day';

@Component({
  selector: 'ui-calendar',
  standalone: true,
  imports: [CommonModule, NgIconComponent, ButtonComponent],
  templateUrl: './calendar.component.html',
  viewProviders: [
    provideIcons({
      bootstrapCaretDownFill,
      heroChevronLeftSolid,
      heroChevronRightSolid,
    }),
  ],
})
export class CalendarComponent implements OnInit {
  @Input() selected?: Date;
  @Output() changed = new EventEmitter<Date>();
  mode: 'days' | 'years' = 'days';
  days = DAYS;
  months = MONTHS;
  month = 0;
  year = 0;
  years: number[] = [];
  monthDays: number[] = [];
  weeks = [0, 1, 2, 3, 4, 5];
  calendarDays: Map<number, CalendarDay[]> = new Map();
  today = new Date();

  ngOnInit(): void {
    this.initialize();
    this.updateCalendar();
  }

  private initialize(): void {
    const selected = this.selected || this.today;
    this.month = selected.getMonth();
    this.year = selected.getFullYear();
  }

  selectDay(day: CalendarDay): void {
    this.selected = new Date(day.year, day.month, day.day);
    this.changed.emit(this.selected);
  }

  changeCalendar(direction: 'next' | 'previous'): void {
    const increment = direction === 'next' ? 1 : -1;
    if (this.mode === 'days') {
      if (this.month === (direction === 'next' ? 11 : 0)) {
        this.year += increment;
        this.month = direction === 'next' ? 0 : 11;
      } else {
        this.month += increment;
      }
    } else if (this.mode === 'years') {
      this.years = this.years.map((year) => year + 24 * increment);
    }
    this.updateCalendar();
  }

  next(): void {
    this.changeCalendar('next');
  }

  previous(): void {
    this.changeCalendar('previous');
  }

  toggleYears(): void {
    if (this.mode === 'days') {
      this.mode = 'years';
      this.updateYears();
    } else {
      this.mode = 'days';
    }
  }

  selectYear(year: number): void {
    this.year = year;
    this.mode = 'days';
    this.updateCalendar();
  }

  private updateYears(): void {
    this.years = Array.from({ length: 24 }, (_, i) => this.year - 12 + i);
  }

  private getDay(date: Date): number {
    const day = date.getDay();

    return day === 0 ? 6 : day - 1;
  }

  private getMonthDetails(): any {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
    const firstDay = this.getDay(new Date(this.year, this.month, 1));
    const lastDay = this.getDay(new Date(this.year, this.month, daysInMonth));
    const previousMonth = this.month === 0 ? 11 : this.month - 1;
    const nextMonth = this.month === 11 ? 0 : this.month + 1;
    const previousMonthYear = this.month === 0 ? this.year - 1 : this.year;
    const nextMonthYear = this.month === 11 ? this.year + 1 : this.year;
    const previousMonthDaysInMonth = new Date(
      previousMonthYear,
      previousMonth + 1,
      0,
    ).getDate();

    return {
      daysInMonth,
      firstDay,
      lastDay,
      previousMonth,
      nextMonth,
      previousMonthYear,
      nextMonthYear,
      previousMonthDaysInMonth,
    };
  }

  private populateDays(
    start: number,
    end: number,
    month: number,
    year: number,
    count: number,
  ): number {
    for (let i = start; i <= end; i++) {
      const week = Math.floor(count / 7);
      if (!this.calendarDays.has(week)) {
        this.calendarDays.set(week, []);
      }

      this.calendarDays.get(week)?.push({
        day: i,
        month: month,
        year: year,
      });

      count += 1;
    }

    return count;
  }

  private updateCalendar(): void {
    const monthDetails = this.getMonthDetails();
    let count = 0;

    this.calendarDays.clear();
    this.monthDays = [];

    count = this.populateDays(
      monthDetails.previousMonthDaysInMonth - monthDetails.firstDay + 1,
      monthDetails.previousMonthDaysInMonth,
      monthDetails.previousMonth,
      monthDetails.previousMonthYear,
      count,
    );

    count = this.populateDays(
      1,
      monthDetails.daysInMonth,
      this.month,
      this.year,
      count,
    );

    this.populateDays(
      1,
      6 - monthDetails.lastDay,
      monthDetails.nextMonth,
      monthDetails.nextMonthYear,
      count,
    );
  }
}
