<div
  *ngIf="pagination && pagination.count !== 0"
  class="flex items-center text-black dark:text-white"
>
  <div class="flex items-center" *ngIf="pagination.pages !== 1">
    @if (pagination.page !== 1) {
      <a
        [routerLink]="['.']"
        [queryParams]="{ page: 1 }"
        [queryParamsHandling]="'merge'"
        class="bg-secondary-base mr-2 flex h-7 w-7 items-center justify-center rounded-full text-black"
      >
        <ng-icon name="heroChevronLeftSolid"></ng-icon>
      </a>
    } @else {
      <button
        [disabled]="true"
        class="bg-secondary-base text-muted-base dark:text-dark-muted mr-2 flex h-7 w-7 items-center justify-center rounded-full"
      >
        <ng-icon name="heroChevronLeftSolid"></ng-icon>
      </button>
    }
    <ng-container *ngFor="let page of pages">
      @if (page !== pagination.page) {
        <a
          [routerLink]="['.']"
          [queryParams]="{ page: page }"
          [queryParamsHandling]="'merge'"
          class="mx-2"
          >{{ page }}</a
        >
      } @else {
        <button
          [disabled]="true"
          class="bg-accent-active mx-2 h-7 w-7 rounded-full font-bold text-white"
        >
          {{ page }}
        </button>
      }
    </ng-container>
    @if (pagination.page !== pagination.pages) {
      <a
        [routerLink]="['.']"
        [queryParams]="{ page: pagination.pages }"
        [queryParamsHandling]="'merge'"
        class="bg-secondary-base ml-2 flex h-7 w-7 items-center justify-center rounded-full text-black"
      >
        <ng-icon name="heroChevronRightSolid"></ng-icon>
      </a>
    } @else {
      <button
        [disabled]="true"
        class="bg-secondary-base text-muted-base dark:text-dark-muted ml-2 flex h-7 w-7 items-center justify-center rounded-full"
      >
        <ng-icon name="heroChevronRightSolid"></ng-icon>
      </button>
    }
  </div>
  <span class="text-muted-base dark:text-dark-muted ml-auto text-sm" i18n
    >Page {{ pagination.page }} of {{ pagination.pages }}</span
  >
</div>
