import { inject, PLATFORM_ID } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { forkJoin, map, of } from 'rxjs';

import { AuthService } from '@lysties/auth/data-access';
import { User } from '@lysties/users/data-access';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

export const appResolver: ResolveFn<(User | null)[]> = () => {
  const auth = inject(AuthService);
  const observables = [auth.isAuthenticated(true) ? auth.loadUser() : of(null)];
  const document = inject(DOCUMENT);
  const platform = inject(PLATFORM_ID);

  return forkJoin(observables).pipe(
    map((results) => {
      if (isPlatformBrowser(platform)) {
        document.getElementById('loader')?.classList.add('hidden');
      }
      return results;
    }),
  );
};
