import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ListMixin } from '@lysties/common/api';

import { Attribute } from './attribute';

@Injectable({
  providedIn: 'root',
})
export class AttributeService {
  private listMixin: ListMixin<Attribute>;
  private url = '/attributes';

  constructor(http: HttpClient) {
    this.listMixin = new ListMixin<Attribute>(http, this.url, Attribute);
  }

  list(query?: { [key: string]: string }) {
    return this.listMixin.list(query);
  }
}
