import {
  AttributeMetadata,
  attributeMetadata,
  resourceMetadata,
} from './metadata';
import { BaseResource } from './resource';

export function Attribute(
  type?: unknown,
  attribute?: string,
  transform?: (value: unknown) => unknown,
) {
  return function (target: BaseResource, property: string) {
    if (!attributeMetadata.has(target.constructor)) {
      attributeMetadata.set(target.constructor, []);
    }

    const metadata: AttributeMetadata = {
      attribute: attribute || property,
      property,
      type,
      transform: transform || ((value) => value),
    };

    attributeMetadata.get(target.constructor)?.push(metadata);
  };
}

export function ApiResource(type: string) {
  return function (target: unknown) {
    resourceMetadata.set(target, type);
  };
}
