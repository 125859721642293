<button
  class="hover:bg-secondary-base border-secondary-active dark:border-dark-active dark:hover:bg-dark-active group flex w-full cursor-pointer rounded-md border bg-transparent px-3 py-2 text-sm shadow-sm transition-colors file:border-0 disabled:cursor-not-allowed disabled:opacity-50"
  #button
  role="combobox"
  (focus)="open()"
  [attr.aria-expanded]="opened"
  type="button"
  #trigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  (click)="open()"
  [ngClass]="{ 'text-muted-base': !date }"
>
  <span
    class="pointer-events-none flex cursor-pointer flex-col items-center justify-center pr-2"
  >
    <ng-icon name="heroCalendarDaysSolid" size="20"></ng-icon>
  </span>
  @if (date) {
    {{ date | date: 'longDate' }}
  } @else {
    <span i18n>Pick a date</span>
  }
</button>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="close()"
  (backdropClick)="close()"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'start',
      originY: hasBottomSpace ? 'bottom' : 'top',
      overlayX: 'start',
      overlayY: hasBottomSpace ? 'top' : 'bottom',
      offsetX: 0,
      offsetY: hasBottomSpace ? 3 : -3
    }
  ]"
>
  <ui-calendar (changed)="onSelectDate($event)" [selected]="date"></ui-calendar>
</ng-template>
