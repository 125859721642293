export const MONTHS: string[] = [
  $localize`January`,
  $localize`February`,
  $localize`March`,
  $localize`April`,
  $localize`May`,
  $localize`June`,
  $localize`July`,
  $localize`August`,
  $localize`September`,
  $localize`October`,
  $localize`November`,
  $localize`December`,
];

export const DAYS: string[] = [
  $localize`Monday`,
  $localize`Tuesday`,
  $localize`Wednesday`,
  $localize`Thursday`,
  $localize`Friday`,
  $localize`Saturday`,
  $localize`Sunday`,
];
