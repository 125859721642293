import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ListMixin, Results } from '@lysties/common/api';
import { StorageService } from '@lysties/common/browser';

import { Location } from './location';
import { Neighborhood } from './neighborhood';
import { City } from './city';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private listMixin: ListMixin<Location>;
  private url = '/locations';
  private selectedLocation: Location = new Location({
    neighborhood: new Neighborhood({
      city: new City({ slug: 'yaounde', name: 'Yaounde' }),
    }),
  });
  private locationChanged: BehaviorSubject<Location> =
    new BehaviorSubject<Location>(this.selectedLocation);

  constructor(
    http: HttpClient,
    private storageService: StorageService,
  ) {
    this.listMixin = new ListMixin<Location>(http, this.url, Location);
    this.readLocationFromStorage();
  }

  list(query?: { [key: string]: string }): Observable<Results<Location>> {
    return this.listMixin.list(query);
  }

  changeLocation(location: Location, notify = false) {
    this.storageService.setItem('city', location.neighborhood.city.slug);
    this.storageService.setItem('neighborhood', location.neighborhood.slug);
    this.selectedLocation = location;

    if (notify) {
      this.locationChanged.next(location);
    }
  }

  getLocation(): BehaviorSubject<Location> {
    return this.locationChanged;
  }

  private readLocationFromStorage(): void {
    const citySlug =
      this.storageService.getItem('city') ??
      this.selectedLocation.neighborhood.city.slug;
    const neighborhoodSlug =
      this.storageService.getItem('neighborhood') ??
      this.selectedLocation.neighborhood.slug;

    const location = new Location({
      neighborhood: new Neighborhood({
        slug: neighborhoodSlug,
        city: new City({ slug: citySlug }),
      }),
    });
    this.changeLocation(location, true);
  }
}
