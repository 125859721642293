import { FormGroup } from '@angular/forms';
import { ApiError } from './api-error';

export class ApiBaseFormComponent {
  form: FormGroup = new FormGroup({});
  errors: ApiError[] = [];

  protected handleErrors(errors: ApiError[]): void {
    errors.forEach((error: ApiError) => {
      const validationErrors: { [key: string]: boolean } = {};
      const formControl = this.form.get(error.source ?? '');

      if (formControl) {
        // Set validation errors on the form
        validationErrors[error.code] = true;
        formControl.setErrors(validationErrors);
        formControl.markAsTouched();
        formControl.markAsDirty();
      } else {
        // Error not matched to a form. Add it to global errors
        // TODO: Handle these cases
        this.errors.push(error);
      }
    });
  }

  hasError(field: string, error: string): boolean {
    const formControl = this.form.get(field);
    return (
      formControl?.errors && formControl?.touched && formControl?.errors[error]
    );
  }
}
