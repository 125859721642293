<form
  class="md:shadow-search dark:border-dark-active md:dark:bg-dark-base flex w-full flex-col items-center md:flex-row md:overflow-hidden md:rounded-t-3xl md:bg-white md:p-0.5 dark:border-0 md:dark:border"
  ngClass
  (submit)="onSubmit()"
  [ngClass]="{
    'dark:bg-dark-base fixed bottom-0 left-0 right-0 top-0 bg-white px-4 pt-[32px]':
      mobileQuery?.matches && isActive,
    'md:rounded-bl-3xl': !suggestCategories,
    'md:rounded-br-3xl': !suggestLocations,
  }"
>
  <div
    class="relative -top-4 flex w-full justify-between md:hidden"
    [ngClass]="{ hidden: !isActive }"
  >
    <button
      type="button"
      ui-button
      icon
      color="secondary"
      (click)="onDeactivate()"
    >
      <ng-icon
        name="bootstrapArrowLeft"
        class="dark:text-white"
        size="20"
      ></ng-icon>
    </button>
    <button type="submit" ui-button color="primary" i18n>Search</button>
  </div>
  <div
    class="shadow-search dark:bg-dark-base dark:border-dark-active flex w-full items-center overflow-hidden rounded-full bg-white pr-4 md:pl-4 md:pr-0 md:shadow-none dark:border md:dark:border-0"
    #categoriesAutocomplete
    [ngClass]="{
      'dark:border-white': mobileQuery?.matches && categoriesFocused,
    }"
  >
    <span class="px-4 py-3 text-black md:hidden dark:text-white">
      <ng-icon name="bootstrapSearch" size="18"></ng-icon>
    </span>
    <ui-autocomplete
      placeholder="Things to do, cafés, restaurants, lounges..."
      i18n-placeholder
      (changed)="onCategorySelected($event)"
      (opened)="onActivate($event)"
      (closed)="suggestCategories = false; categoriesFocused = false"
      (typed)="onSearchCategories($event)"
      [options]="categories"
      [width]="
        mobileQuery?.matches
          ? categoriesAutocomplete.clientWidth + 64
          : categoriesAutocomplete.clientWidth + 10
      "
      [offsetX]="mobileQuery?.matches ? -66 : -19"
      [offsetY]="mobileQuery?.matches ? 80 : 3"
      [value]="selectedCategoryOption"
    ></ui-autocomplete>
  </div>
  <div
    class="border-light-base dark:border-dark-active mx-2 hidden h-6 border-l md:inline-flex"
  ></div>
  <div
    class="shadow-search dark:bg-dark-base dark:border-dark-active mt-4 flex w-full items-center overflow-hidden rounded-full bg-white pl-0 pr-4 md:mt-0 md:pl-1 md:pr-0 md:shadow-none dark:border md:dark:border-0"
    #locationsAutocomplete
    [ngClass]="{
      'hidden md:flex':
        (mobileQuery?.matches && !isActive) || mobileQuery === undefined,
      flex: (mobileQuery?.matches && isActive) || mobileQuery !== undefined,
      'dark:border-white': mobileQuery?.matches && locationsFocused,
    }"
  >
    <span class="top-0.5 px-4 py-3 text-black md:hidden dark:text-white">
      <ng-icon name="bootstrapGeoAlt" size="18"></ng-icon>
    </span>
    <ui-autocomplete
      placeholder="Address, neighborhood, city or region"
      i18n-placeholder
      (changed)="onLocationSelected($event)"
      (opened)="onSuggestLocations($event)"
      (closed)="suggestLocations = false; locationsFocused = false"
      (typed)="onSearchLocations($event)"
      [options]="locations"
      [width]="
        mobileQuery?.matches
          ? categoriesAutocomplete.clientWidth + 64
          : locationsAutocomplete.clientWidth + 10
      "
      [offsetX]="mobileQuery?.matches ? -66 : -11.5"
      [offsetY]="mobileQuery?.matches ? 13 : 3"
      [value]="selectedLocationOption"
    ></ui-autocomplete>
    <button
      ui-flat-button
      color="primary"
      icon
      type="submit"
      class="hidden items-center md:ml-2 md:flex"
      aria-label="Search"
      i18n-aria-label
    >
      @if (loading) {
        <svg
          class="h-5 w-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M11.636 12.52C10.5128 13.4173 9.08856 13.8505 7.6559 13.7307C6.22324 13.6108 4.89086 12.9468 3.93241 11.8753C2.97395 10.8037 2.46217 9.40583 2.50218 7.96872C2.54219 6.5316 3.13094 5.16436 4.14753 4.14777C5.16411 3.13119 6.53136 2.54243 7.96847 2.50242C9.40559 2.46242 10.8035 2.9742 11.875 3.93265C12.9466 4.89111 13.6105 6.22348 13.7304 7.65614C13.8503 9.0888 13.4171 10.513 12.5197 11.6362L17.3172 16.4325C17.4346 16.5498 17.5005 16.709 17.5005 16.875C17.5005 17.0409 17.4346 17.2001 17.3172 17.3175C17.1999 17.4348 17.0407 17.5008 16.8747 17.5008C16.7088 17.5008 16.5496 17.4348 16.4322 17.3175L11.6372 12.52H11.636ZM12.4997 8.12498C12.4997 7.55044 12.3866 6.98153 12.1667 6.45074C11.9468 5.91994 11.6246 5.43764 11.2183 5.03138C10.8121 4.62513 10.3298 4.30287 9.79897 4.083C9.26817 3.86314 8.69927 3.74998 8.12473 3.74998C7.5502 3.74998 6.98129 3.86314 6.45049 4.083C5.91969 4.30287 5.4374 4.62513 5.03114 5.03138C4.62488 5.43764 4.30262 5.91994 4.08276 6.45074C3.86289 6.98153 3.74973 7.55044 3.74973 8.12498C3.74973 9.2853 4.21067 10.3981 5.03114 11.2186C5.85161 12.039 6.96441 12.5 8.12473 12.5C9.28505 12.5 10.3979 12.039 11.2183 11.2186C12.0388 10.3981 12.4997 9.2853 12.4997 8.12498Z"
            fill="white"
          />
        </svg>
      }
    </button>
  </div>
</form>
