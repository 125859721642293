export const ALLOWED_BUSINESS_FILTERS = [
  'loc',
  'city',
  'state',
  'cat',
  'subcat',
  'attrs',
];

export const WEEKDAYS: { [key: string]: string } = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};
