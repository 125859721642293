import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { featherX } from '@ng-icons/feather-icons';

import { AlertComponent } from '../../alert/alert.component';

@Component({
  selector: 'ui-toastr',
  standalone: true,
  imports: [CommonModule, NgIconComponent, AlertComponent],
  templateUrl: './toastr.component.html',
  viewProviders: [provideIcons({ featherX })],
})
export class ToastrComponent {
  @Input() color: 'success' | 'error' | 'warning' | 'info' = 'info';
  @Input() title = '';
  @Input() message = '';
  @Input() dismissible = true;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  onClose(): void {
    this.closed.emit();
  }
}
