import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';

@ApiResource('AttributeCategory')
export class AttributeCategory extends BaseResource {
  @Attribute()
  slug = '';

  @Attribute()
  name = '';

  constructor(name = '', slug = '') {
    super();

    this.name = name;
    this.slug = slug;
  }
}
