import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { featherInstagram, featherFacebook } from '@ng-icons/feather-icons';
import { bootstrapTwitterX } from '@ng-icons/bootstrap-icons';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  viewProviders: [
    provideIcons({
      featherInstagram,
      featherFacebook,
      bootstrapTwitterX,
    }),
  ],
})
export class MaintenanceComponent {}
