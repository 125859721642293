import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';

import { Observable, catchError, map } from 'rxjs';

import { API_ROOT_TOKEN, API_VERSION_TOKEN } from '@lysties/common/http';

import { deserialize } from '../api';
import { ApiDetailResponse, BaseResource } from '../resource';
import { BaseMixin } from './base.mixin';

export class UploadMixin<T extends BaseResource> extends BaseMixin {
  constructor(
    private http: HttpClient,
    private endpoint: string,
    protected readonly cls: new () => T,
    @Inject(API_ROOT_TOKEN) private apiRoot: string,
    @Inject(API_VERSION_TOKEN) private apiVersion: string
  ) {
    super();
  }

  upload(file: File, key: string, ...args: string[]): Observable<T> {
    const endpoint = `${this.apiRoot}/${this.apiVersion}${this.endpoint}/`;
    const url = args.length > 0 ? `${endpoint}${args.join('/')}/` : endpoint;
    const formData = new FormData();
    formData.append(key, file);

    return this.http.post<ApiDetailResponse>(url, formData).pipe(
      map((response) =>
        deserialize<T>(this.cls, response.data, response.included)
      ),
      catchError(this.handleErrors)
    );
  }
}
