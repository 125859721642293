import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ListMixin, RetrieveMixin } from '@lysties/common/api';

import { Category } from './category';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private listMixin: ListMixin<Category>;
  private retrieveMixin: RetrieveMixin<Category>;
  private url = '/categories';

  constructor(private http: HttpClient) {
    this.listMixin = new ListMixin<Category>(http, this.url, Category);
    this.retrieveMixin = new RetrieveMixin<Category>(http, this.url, Category);
  }

  list(query?: { [key: string]: string }) {
    return this.listMixin.list(query);
  }

  retrieve(id: string) {
    return this.retrieveMixin.retrieve(id);
  }
}
