import { InjectionToken } from '@angular/core';

export const GOOGLE_MAPS_API_KEY = new InjectionToken<string>(
  'Google Maps API key',
);

export const GOOGLE_MAPS_DEFAULT_ID = new InjectionToken<string>(
  'Google Maps default map ID',
);

export const GOOGLE_MAPS_DARK_ID = new InjectionToken<string>(
  'Google Maps dark map ID',
);
