<div class="fixed right-10 top-10 md:top-24 text-sm z-50">
  @for (toastr of toastrs; track toastr) {
    <ui-toastr
      [color]="toastr.color"
      [title]="toastr.title"
      [message]="toastr.message"
      [dismissible]="toastr.dismissible"
      (closed)="remove(toastr)"
    ></ui-toastr>
  }
</div>
