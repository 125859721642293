import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';

@ApiResource('Photo')
export class Photo extends BaseResource {
  @Attribute()
  authorId = '';

  @Attribute()
  authorName = '';

  @Attribute()
  businessName = '';

  @Attribute()
  businessSlug = '';

  @Attribute()
  caption = '';

  @Attribute(Date)
  created = new Date();

  @Attribute()
  image = '';

  @Attribute()
  thumbnail = '';

  @Attribute()
  thumbnailLarge = '';

  constructor(image = '', thumbnail = '', thumbnailLarge = '') {
    super();

    this.image = image;
    this.thumbnail = thumbnail;
    this.thumbnailLarge = thumbnailLarge;
  }
}
