@if (loading) {
  <div class="fixed bottom-0 left-0 right-0 top-0 z-50 h-1 overflow-hidden">
    <ui-progress-bar variant="primary"></ui-progress-bar>
  </div>
}

<div>
  <router-outlet></router-outlet>

  <ui-toastrs></ui-toastrs>
</div>
