import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CreateMixin, DeleteMixin, ListMixin } from '@lysties/common/api';

import { Review } from './review';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  private listMixin: ListMixin<Review>;
  private createMixin: CreateMixin<Review>;
  private deleteMixin: DeleteMixin<Review>;
  private url = '/reviews';

  constructor(http: HttpClient) {
    this.listMixin = new ListMixin<Review>(http, this.url, Review);
    this.createMixin = new CreateMixin<Review>(http, this.url, Review);
    this.deleteMixin = new DeleteMixin<Review>(http, this.url, Review);
  }

  list(query?: { [key: string]: string }) {
    return this.listMixin.list(query);
  }

  listForBusiness(businessSlug: string, query?: { [key: string]: string }) {
    return this.listMixin.list(query, `/businesses/${businessSlug}/reviews/`);
  }

  create(data: unknown): Observable<Review> {
    return this.createMixin.create(data);
  }

  delete(id: string): Observable<void> {
    return this.deleteMixin.delete(id);
  }
}
