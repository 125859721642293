import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { API_ROOT_TOKEN } from '@lysties/common/http';

import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const apiRoot = inject(API_ROOT_TOKEN);

  if (req.url.startsWith('http') && !req.url.startsWith(apiRoot)) {
    return next(req);
  }

  const auth = inject(AuthService);
  const token = auth.getAccessToken();

  if (!token) {
    return next(req);
  }

  const headers = req.headers.set('Authorization', `Bearer ${token}`);
  const apiRequest = req.clone({ headers });

  return next(apiRequest);
};
