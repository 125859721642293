import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, finalize } from 'rxjs';

import { ListMixin, Results, RetrieveMixin } from '@lysties/common/api';
import { Category } from '@lysties/classification/data-access';
import { City, Location } from '@lysties/locations/data-access';

import { Business } from './business';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private listMixin: ListMixin<Business>;
  private retrieveMixin: RetrieveMixin<Business>;
  private url = '/businesses';
  private loading = new BehaviorSubject<boolean>(false);
  private searchedCategory = new BehaviorSubject<Category | null>(null);
  private searchedLocation = new BehaviorSubject<Location | City | null>(null);

  constructor(private http: HttpClient) {
    this.listMixin = new ListMixin<Business>(http, this.url, Business);
    this.retrieveMixin = new RetrieveMixin<Business>(http, this.url, Business);
  }

  list(
    query?: { [key: string]: string },
    url?: string
  ): Observable<Results<Business>> {
    this.loading.next(true);
    return this.listMixin
      .list(query, url)
      .pipe(finalize(() => this.loading.next(false)));
  }

  retrieve(slug: string): Observable<Business> {
    this.loading.next(true);
    return this.retrieveMixin
      .retrieve(slug)
      .pipe(finalize(() => this.loading.next(false)));
  }

  getLoading(): BehaviorSubject<boolean> {
    return this.loading;
  }

  getSearchedCategory(): BehaviorSubject<Category | null> {
    return this.searchedCategory;
  }

  nextSearchedCategory(category: Category | null): void {
    this.searchedCategory.next(category);
  }

  getSearchedLocation(): BehaviorSubject<Location | City | null> {
    return this.searchedLocation;
  }

  nextSearchedLocation(location: Location | City | null): void {
    this.searchedLocation.next(location);
  }

  save(business: Business): Observable<void> {
    return this.http.post<void>(`${this.url}/${business.slug}/save/`, null);
  }

  unsave(business: Business): Observable<void> {
    return this.http.post<void>(`${this.url}/${business.slug}/unsave/`, null);
  }
}
