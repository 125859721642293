import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Params, ActivatedRoute, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroChevronLeftSolid,
  heroChevronRightSolid,
} from '@ng-icons/heroicons/solid';
import { ButtonComponent } from '../button/button.component';
import { Pagination } from './pagination';

@Component({
  selector: 'ui-pagination',
  templateUrl: './pagination.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, NgIconComponent, ButtonComponent],
  providers: [provideIcons({ heroChevronLeftSolid, heroChevronRightSolid })],
})
export class PaginationComponent implements OnInit {
  @Input()
  set pagination(value: Pagination) {
    this._pagination = value;
    this.pages = [];

    for (let i = 1; i <= this._pagination.pages; i++) {
      this.pages.push(i);
    }
  }

  get pagination(): Pagination {
    return this._pagination;
  }

  private _pagination!: Pagination;
  pages: number[] = [];
  params!: Params;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
    });
  }
}
