export class BaseResource {
  public id?: string;
  public relationships?: { [key: string]: Relationship };

  constructor(init?: Partial<BaseResource>) {
    Object.assign(this, init);
  }
}

export class Pagination {
  constructor(
    public page = 1,
    public pages = 1,
    public count = 0,
    public first: string | null = null,
    public last: string | null = null,
    public next: string | null = null,
    public previous: string | null = null,
  ) {}
}

export class Results<T extends BaseResource> {
  public data: T[] = [];
  public pagination: Pagination = new Pagination();
}

export interface Resource {
  type?: string;
  id?: string;
  attributes?: Record<string, unknown>;
  relationships?: Record<string, { data: Resource | Resource[] }>;
}

export interface ApiDetailResponse {
  data: Resource;
  included: Resource[];
}

export interface ApiListResponse {
  data: Resource[];
  included: Resource[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    pagination: {
      count: number;
      page: number;
      pages: number;
    };
  };
}

export interface Identifier {
  id: string;
  type: string;
}

export interface Relationship {
  data: Identifier;
}
