import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, Input, booleanAttribute } from '@angular/core';
import { RouterLink } from '@angular/router';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { featherInstagram, featherFacebook } from '@ng-icons/feather-icons';
import { bootstrapTwitterX } from '@ng-icons/bootstrap-icons';

import { ButtonComponent } from '@lysties/shared/ui';
import { WINDOW_TOKEN } from '@lysties/common/browser';

/**
 * Footer component.
 * Provides a standard footer with about and social links.
 */
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonComponent, NgIconComponent],
  templateUrl: './footer.component.html',
  viewProviders: [
    provideIcons({
      featherInstagram,
      bootstrapTwitterX,
      featherFacebook,
    }),
  ],
})
export class FooterComponent {
  @Input({ transform: booleanAttribute }) lite = false;
  year = new Date().getFullYear();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {}

  changeLanguage(lang: string) {
    this.document.cookie = `firebase-language-override=${lang}`;
    this.window.location.reload();
  }
}
