import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from '../toastr.service';
import { Toastr } from '../toastr';
import { ToastrComponent } from '../toastr/toastr.component';

@Component({
  selector: 'ui-toastrs',
  standalone: true,
  imports: [CommonModule, ToastrComponent],
  templateUrl: './toastrs.component.html',
})
export class ToastrsComponent {
  toastrs: Toastr[] = [];

  constructor(private toastrService: ToastrService) {
    this.toastrs = toastrService.getToastrs();
  }

  remove(toastr: Toastr): void {
    this.toastrService.remove(toastr);
    this.toastrs = this.toastrService.getToastrs();
  }
}
