import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Scroll } from '@angular/router';

import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';

/**
 * The base layout component.
 * Provides a standard layout with a white navbar, black footer and a content.
 */
@Component({
  selector: 'app-base-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, FooterComponent],
  templateUrl: './base-layout.component.html',
})
export class BaseLayoutComponent {
  focus = false;

  constructor(router: Router) {
    router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          const parts = event.url.split('/');

          if (parts.length === 3 && parts[1] === 'businesses') {
            this.focus = true;
            return;
          }
        }

        if (event instanceof Scroll && event.routerEvent.url === '/') {
          this.focus = true;
          return;
        }

        this.focus = false;
      },
    });
  }
}
