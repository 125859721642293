import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { AutocompleteOption } from './autocomplete-option';
import { Subject, delay, fromEvent, takeUntil } from 'rxjs';

@Component({
  selector: 'ui-autocomplete',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  templateUrl: './autocomplete.component.html',
  styles: ':host {display: block; width: 100%;}',
})
export class AutocompleteComponent implements AfterViewInit, OnDestroy {
  isOpen = false;
  @Input() placeholder = '';
  @Input() width = 0;
  @Input() offsetX = 0;
  @Input() offsetY = 0;
  @Input() set options(options: AutocompleteOption<unknown>[]) {
    this._options = options;
  }
  get options(): AutocompleteOption<unknown>[] {
    return this._options;
  }
  @Input() set value(value: AutocompleteOption<unknown> | null) {
    if (value && this.input) {
      this._value = value;
      this.input.nativeElement.value = value.label;
    }
  }
  get value(): AutocompleteOption<unknown> {
    return this._value;
  }

  @Output() readonly changed = new EventEmitter<AutocompleteOption<unknown>>();
  @Output() readonly typed = new EventEmitter<string>();
  @Output() readonly opened = new EventEmitter<string>();
  @Output() readonly closed = new EventEmitter<void>();

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  private _options: AutocompleteOption<unknown>[] = [];
  private _value!: AutocompleteOption<unknown>;
  private destroyed$ = new Subject<void>();

  onFocus(): void {
    const value = this.input.nativeElement.value;
    this.input.nativeElement.select();
    this.opened.emit(value);
    this.isOpen = true;
  }

  onInput(event: Event): string {
    const value = (event.target as HTMLInputElement).value;
    this.typed.emit(value);
    return value;
  }

  onBlur(): void {
    this.isOpen = false;
    this.closed.emit();
  }

  onSelect(option: AutocompleteOption<unknown>): void {
    this.value = option;
    this.changed.emit(this.value);
    this.input.nativeElement.value = option.label;
  }

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'blur')
      .pipe(takeUntil(this.destroyed$), delay(100))
      .subscribe({
        next: () => this.onBlur(),
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  splitLabel(label: string): string[] {
    const value = this.input.nativeElement.value;
    const pattern = `(${this.escapeRegExp(value)})`;
    const regex = new RegExp(pattern, 'gi');
    return label.split(regex);
  }

  private escapeRegExp(value: string): string {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
