import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RetrieveMixin } from '@lysties/common/api';

import { User } from './user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private retrieveMixin: RetrieveMixin<User>;
  private url = '/users';

  constructor(http: HttpClient) {
    this.retrieveMixin = new RetrieveMixin<User>(http, this.url, User);
  }

  getMe(): Observable<User> {
    return this.retrieveMixin.retrieve('me', ['profile.avatars']);
  }

  retrieve(id: string, include: string[] = []): Observable<User> {
    return this.retrieveMixin.retrieve(id, include);
  }
}
