import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  DeleteMixin,
  ListMixin,
  Results,
  UploadMixin,
} from '@lysties/common/api';
import { API_ROOT_TOKEN, API_VERSION_TOKEN } from '@lysties/common/http';

import { Photo } from './photo';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  private listMixin: ListMixin<Photo>;
  private uploadMixin: UploadMixin<Photo>;
  private deleteMixin: DeleteMixin<Photo>;
  private url = '/photos';

  constructor(
    http: HttpClient,
    @Inject(API_ROOT_TOKEN) apiRoot: string,
    @Inject(API_VERSION_TOKEN) apiVersion: string
  ) {
    this.listMixin = new ListMixin(http, this.url, Photo);
    this.uploadMixin = new UploadMixin(
      http,
      this.url,
      Photo,
      apiRoot,
      apiVersion
    );
    this.deleteMixin = new DeleteMixin(http, this.url, Photo);
  }

  listForBusiness(
    businessSlug: string,
    query?: { [key: string]: string }
  ): Observable<Results<Photo>> {
    return this.listMixin.list(query, `/businesses/${businessSlug}/photos/`);
  }

  list(query?: { [key: string]: string }): Observable<Results<Photo>> {
    return this.listMixin.list(query);
  }

  upload(file: File, model: string, modelId: string): Observable<Photo> {
    return this.uploadMixin.upload(file, 'image', model, modelId);
  }

  delete(id: string): Observable<void> {
    return this.deleteMixin.delete(id);
  }
}
