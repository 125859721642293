import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import { deserialize } from '../api';
import {
  ApiListResponse,
  BaseResource,
  Pagination,
  Resource,
  Results,
} from '../resource';

function createPagination(response: ApiListResponse): Pagination {
  const pagination = new Pagination(
    response.meta.pagination.page,
    response.meta.pagination.pages,
    response.meta.pagination.count,
    response.links.first,
    response.links.last,
    response.links.next,
    response.links.prev
  );

  return pagination;
}

function createHttpParams(query?: { [key: string]: string }): HttpParams {
  let params = new HttpParams();
  if (query !== undefined) {
    Object.keys(query).forEach((key: string) => {
      if (key === 'include') {
        params = params.append(key, query[key]);
        return;
      }

      const values = query[key].split(',');
      values.forEach((value: string) => {
        if (value.trim()) {
          params = params.append(key, value);
        }
      });
    });
  }
  return params;
}

export class ListMixin<T extends BaseResource> {
  constructor(
    private http: HttpClient,
    private endpoint: string,
    protected readonly cls: new () => T
  ) {}

  list(
    query?: { [key: string]: string },
    url?: string
  ): Observable<Results<T>> {
    const params = createHttpParams(query);

    return this.http
      .get<ApiListResponse>(url ?? `${this.endpoint}/`, { params })
      .pipe(
        map((response: ApiListResponse) => {
          const results = new Results<T>();
          results.pagination = createPagination(response);

          response.data.forEach((data: Resource) => {
            results.data.push(
              deserialize<T>(this.cls, data, response.included)
            );
          });
          return results;
        })
      );
  }
}
