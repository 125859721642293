<section class="my-16">
  <h2 i18n class="mb-16 text-center text-4xl font-medium">Top categories</h2>

  <div class="px-4 lg:grid lg:grid-cols-12 lg:px-0">
    <div class="col-span-8 col-start-3">
      <div class="grid grid-cols-12 gap-4">
        @if (categories$ | async; as categories) { @for (category of
        categories.data; track category) {
        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
          <a
            [routerLink]="['/businesses']"
            [queryParams]="{ cat: category.slug, city: city.slug }"
            class="relative flex h-[200px] w-full overflow-hidden rounded-lg"
          >
            <img
              [ngSrc]="category.image"
              fill
              class="h-full w-full object-cover"
              [alt]="category.slug"
            />
            <div
              class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center bg-black bg-opacity-30 p-4 duration-300 ease-in-out hover:bg-opacity-0 focus:bg-opacity-0"
            >
              <h3 class="text-center text-3xl font-bold text-white">
                {{ category.name }}
              </h3>
            </div>
          </a>
        </div>
        } }
      </div>
    </div>
  </div>
</section>
