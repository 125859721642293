import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UpdateMixin } from '@lysties/common/api';

import { Profile } from './profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private updateMixin: UpdateMixin<Profile>;
  private url = '/profiles';

  constructor(http: HttpClient) {
    this.updateMixin = new UpdateMixin(http, this.url, Profile);
  }

  update(id: string, data: Partial<Profile>): Observable<Profile> {
    return this.updateMixin.update(id, data);
  }
}
