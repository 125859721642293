import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';

@ApiResource('RatingCategory')
export class RatingCategory extends BaseResource {
  @Attribute()
  name = '';

  constructor(init?: Partial<RatingCategory>) {
    super(init);

    Object.assign(this, init);
  }
}
