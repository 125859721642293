import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { ApiDetailResponse, BaseResource } from '../resource';
import { deserialize, serialize } from '../api';

export class UpdateMixin<T extends BaseResource> {
  constructor(
    private http: HttpClient,
    private endpoint: string,
    protected readonly cls: new () => T
  ) {}

  update(id: string, data: Partial<T>): Observable<T> {
    return this.http
      .patch<ApiDetailResponse>(
        `${this.endpoint}/${id}/`,
        serialize(this.cls, { id, ...data })
      )
      .pipe(
        map((response: ApiDetailResponse) => {
          return deserialize(this.cls, response.data, []);
        })
      );
  }
}
