import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton.component.html',
})
export class SkeletonComponent {
  @Input() color = 'default';
  @HostBinding('class.animate-pulse') animate = true;
  @HostBinding('class.bg-slate-200.dark:bg-dark-active') style =
    this.color === 'default';
  @HostBinding('class.block') visible = true;
}
