import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';
import { Region } from './region';

@ApiResource('City')
export class City extends BaseResource {
  @Attribute() name = '';
  @Attribute() slug = '';
  @Attribute(Region, 'region') region = new Region();

  constructor(init?: Partial<City>) {
    super(init);

    Object.assign(this, init);
  }

  display(): string {
    return `${this.name}, ${this.region.code}`;
  }
}
