import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  booleanAttribute,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'div[ui-card], a[ui-card]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
})
export class CardComponent extends BaseComponent implements AfterViewInit {
  @Input({ transform: booleanAttribute }) borders = true;
  @Input() classes = '';

  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);

    // Default card classes
    this.addToClassList('rounded-xl p-4 flex flex-col');
  }

  ngAfterViewInit(): void {
    if (this.borders) {
      this.addToClassList(
        'border-secondary-base dark:border-dark-active border',
      );
    }

    if (this.classes) {
      this.addToClassList(this.classes);
    }
  }
}
