import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';
import { RatingCategory } from './rating-category';

@ApiResource('ReviewRating')
export class ReviewRating extends BaseResource {
  @Attribute()
  rating = 0;

  @Attribute(RatingCategory, 'category')
  category!: RatingCategory;

  constructor(init?: Partial<ReviewRating>) {
    super(init);

    Object.assign(this, init);
  }
}
