import {
  CommonModule,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { Observable, finalize, shareReplay } from 'rxjs';

import { Results } from '@lysties/common/api';
import { Highlight, HighlightService } from '@lysties/highlights/data-access';
import { LocationService, Location } from '@lysties/locations/data-access';
import { ButtonComponent } from '@lysties/shared/ui';

@Component({
  selector: 'app-highlights-carousel',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterModule, ButtonComponent],
  templateUrl: './highlights-carousel.component.html',
})
export class HighlightsCarouselComponent implements OnInit, OnDestroy {
  highlights$!: Observable<Results<Highlight>>;
  loading = false;
  activeSlideIndex = 0;
  private carouselInterval: ReturnType<typeof setInterval> | null = null;
  private interval = 5000;
  @ViewChild('container', { static: true })
  private container!: ElementRef<HTMLDivElement>;
  private location!: Location;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private highlightService: HighlightService,
    private locationService: LocationService,
  ) {}

  ngOnInit() {
    this.locationService.getLocation().subscribe({
      next: (location: Location) => {
        this.location = location;
        this.loadHighlights();
      },
    });
  }

  private loadHighlights(): void {
    this.loading = true;
    this.highlights$ = this.highlightService
      .list({ 'filter[city]': this.location.neighborhood.city.slug })
      .pipe(
        shareReplay(1),
        finalize(() => {
          this.loading = false;
          this.startCarousel();
        }),
      );
  }

  startCarousel(): void {
    if (this.carouselInterval !== null) return;
    // TODO: test the callback of this setInterval
    if (isPlatformBrowser(this.platformId)) {
      this.carouselInterval = setInterval(() => {
        const itemsCount =
          this.container?.nativeElement.querySelectorAll(
            '[role="tabpanel"]',
          ).length;
        if (itemsCount) {
          this.activeSlideIndex = (this.activeSlideIndex + 1) % itemsCount;
        }
      }, this.interval);
    }
  }

  stopCarousel(): void {
    clearInterval(this.carouselInterval as ReturnType<typeof setInterval>);
    this.carouselInterval = null;
  }

  setActiveSlideIndex(index: number): void {
    this.stopCarousel();
    this.activeSlideIndex = index;
    this.startCarousel();
  }

  ngOnDestroy(): void {
    this.stopCarousel();
  }
}
