import { HttpClient } from '@angular/common/http';

import { Observable, catchError, map } from 'rxjs';

import { deserialize, serialize } from '../api';
import { ApiDetailResponse, BaseResource } from '../resource';
import { BaseMixin } from './base.mixin';

export class CreateMixin<T extends BaseResource> extends BaseMixin {
  constructor(
    private http: HttpClient,
    private endpoint: string,
    protected readonly cls: new () => T,
  ) {
    super();
  }

  create(data: unknown): Observable<T> {
    return this.http
      .post<ApiDetailResponse>(`${this.endpoint}/`, serialize(this.cls, data))
      .pipe(
        map((response: ApiDetailResponse) => {
          return deserialize<T>(this.cls, response.data, response.included);
        }),
        catchError(this.handleErrors),
      );
  }
}
