import { Component } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterModule,
} from '@angular/router';
import { ProgressBarComponent, ToastrsComponent } from '@lysties/shared/ui';

@Component({
  standalone: true,
  imports: [RouterModule, ProgressBarComponent, ToastrsComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loading = false;

  constructor(router: Router) {
    // Listen to router events in order to set loading flag to true or false.
    router.events.subscribe({
      next: (event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.loading = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loading = false;
        }
      },
    });
  }
}
