import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from './auth.service';
import { StorageService } from '@lysties/common/browser';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const storage = inject(StorageService);

  if (!auth.isAuthenticated(true)) {
    auth.signOut();
    storage.setItem('redirect', state.url);
    router.navigate(['/signin']);
    return false;
  }
  return true;
};
