import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { FeaturedCategoriesComponent } from '@lysties/classification/featured';
import { HighlightsCarouselComponent } from '@lysties/highlights/carousel';
import { RecentReviewsComponent } from '@lysties/reviews/recent';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    HighlightsCarouselComponent,
    RecentReviewsComponent,
    FeaturedCategoriesComponent,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {}
