<div class="flex min-h-screen flex-col">
  <app-header transparent focus lite></app-header>
  <main class="flex flex-1 flex-col overflow-x-hidden">
    <div class="fixed h-screen w-screen">
      <img
        ngSrc="/assets/images/highlight.jpg"
        fill
        class="object-cover"
        priority=""
        alt=""
      />
    </div>
    <div
      class="absolute flex min-h-screen w-full flex-col justify-end bg-black bg-opacity-40 px-4 lg:px-10"
    >
      <div
        class="dark:bg-dark-base my-10 ml-auto max-w-md rounded-xl bg-white p-4 lg:px-6 lg:py-10"
      >
        <router-outlet></router-outlet>
      </div>
      <app-footer lite></app-footer>
    </div>
  </main>
</div>
