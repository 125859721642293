import { OverlayModule } from '@angular/cdk/overlay';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { heroCalendarDaysSolid } from '@ng-icons/heroicons/solid';
import { NgIcon, provideIcons } from '@ng-icons/core';

import { WINDOW_TOKEN } from '@lysties/common/browser';

import { ButtonComponent } from '../button/button.component';
import { InputComponent } from '../input/input.component';
import { CalendarComponent } from '../calendar/calendar.component';

@Component({
  selector: 'ui-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
    NgIcon,
    ButtonComponent,
    CalendarComponent,
    InputComponent,
  ],
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatePickerComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
  viewProviders: [provideIcons({ heroCalendarDaysSolid })],
})
export class DatePickerComponent implements ControlValueAccessor, Validator {
  @ViewChild('button') trigger!: ElementRef<HTMLButtonElement>;
  @ViewChild('mobileInput') mobileInput!: ElementRef<HTMLInputElement>;
  hasBottomSpace = false;

  date?: Date;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_date: Date) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touched = false;
  disabled = false;
  opened = false;

  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  writeValue(date: Date): void {
    this.date = date;
  }

  registerOnChange(onChange: (date: Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelectDate(date: Date): void {
    this.close();
    this.markAsTouched();
    this.date = date;
    this.onChange(date);
  }

  open(): void {
    const rect = this.trigger.nativeElement.getBoundingClientRect();
    const bottomSpace = this.window.innerHeight - rect.bottom;
    this.hasBottomSpace = bottomSpace > 300;
    this.opened = true;
  }

  close(): void {
    this.opened = false;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const date = new Date(control.value);
    if (isNaN(date.getTime())) {
      return { invalidDate: true };
    }

    return null;
  }
}
